import React, { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
interface SystemInfoData {
  browser: { name: string; version: string };
  os: { name: string; version: string };
  device: { type: string; model: string; cores: number | null; memory: number | null };
  hash: string;
}

const DeviceComponent: React.FC = () => {
  const [systemInfo, setSystemInfo] = useState<SystemInfoData | null>(null);

  useEffect(() => {
    const ua = new UAParser();

    const fetchClientHints = async () => {
      const browser = await ua.getBrowser().withClientHints();
      const os = await ua.getOS().withClientHints();
      const device = ua.getDevice();

      const data = {
        browser: {
          name: browser.name || 'Unknown',
          version: browser.version || 'Unknown',
        },
        os: {
          name: os.name || 'Unknown',
          version: os.version || 'Unknown',
        },
        device: {
          type: device.type || 'PC',
          model: device.model || 'PC',
          cores: navigator.hardwareConcurrency || null,
          memory: (navigator as any).deviceMemory || null,
        },
      };

      const hash = await getHash(JSON.stringify(data));
      const systemInfoData: SystemInfoData = { ...data, hash };

      setSystemInfo(systemInfoData);
      globalStore.set({ ...globalStore.get(), device: { hash: hash, data: systemInfoData } });
    };

    fetchClientHints();
  }, []);

  return (
    <div>
      <div>
        <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
          Device: <span className="text-sm border px-1">{systemInfo?.hash.slice(0, 12)}</span>
        </h2>
        <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
          <JsonView src={systemInfo} />
        </div>
      </div>
    </div>
  );
};

export default DeviceComponent;
